import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { CurrentUser } from './userSlice';



const initialState: CurrentUser[] = [{
  id: null,
  key: null,
  name: undefined,
  last_name: undefined,
  full_name: undefined,
  avatar_url: null,
  phone_number: null,
  email: undefined,
  roles: [],
  national_nid: undefined,
  archived: false
}]

const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
      setCurrentUsers: (state, action: PayloadAction<CurrentUser[]>) => {
       return action.payload
      },
      logoutCurrentUsers: () => initialState
    },
  });


export const { setCurrentUsers, logoutCurrentUsers } = usersSlice.actions;
export const usersReducer = usersSlice.reducer
export const selectCurrentUsers = (state: any) => state.currentUsers