import { PayloadAction, createSlice } from '@reduxjs/toolkit'

export interface CurrentUser {
  id: number | null
  key: number | null
  name: string | undefined
  last_name: string | undefined
  full_name: string | undefined
  avatar_url: string |  null
  phone_number: string | null
  email: string | undefined
  roles: [] | null
  national_nid: string | undefined
  archived: boolean
}

const initialState: CurrentUser = {
  id: null,
  key: null,
  name: undefined,
  last_name: undefined,
  full_name: undefined,
  avatar_url: null,
  phone_number: null,
  email: undefined,
  roles: [],
  national_nid: undefined,
  archived: false
}

export const userSlice = createSlice({
  name: 'currentUser',
  initialState,
  reducers: {
    setCurrentUser: (state, action) => {
      
      return { ...state, ...action.payload };
    },
    logoutCurrentUser: () => initialState
  },
})

export const { setCurrentUser, logoutCurrentUser } = userSlice.actions
export const userReducer = userSlice.reducer
export const selectCurrentUser = (state: { currentUser: { selectedUser: CurrentUser } }) => state.currentUser.selectedUser;
