import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface DownloadItem {
  id: number;
  name: string;
  status: string;
  since: string;
}

const initialState: DownloadItem[] = [];

export const downloadsSlice = createSlice({
  name: 'userDownloads',
  initialState,
  reducers: {
    setUserDownloads: (state, action: PayloadAction<DownloadItem[]>) =>  {
      return action.payload
     },

  },
})

export const { setUserDownloads } = downloadsSlice.actions
export const downloadsReducer = downloadsSlice.reducer
export const selectedDownloads = (state: any) => state.userDownloads
