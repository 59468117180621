import { createSlice } from '@reduxjs/toolkit'

export interface CurrentConnection{
    id: number | null
    name: string | null
    endpoint: string | null
    scope: string | null
}

const initialState = {
    id: null,
    name: null,
    endpoint: null,
    scope: null
}

export const connectionSlice = createSlice({
    name: 'currentConnection',
    initialState,
    reducers:{
        setCurrentConnection: (state, action) => {
            const {
                id,
                name,
                endpoint,
                scope

            } = action.payload
            return {
                ...state,
                id,
                name,
                endpoint,
                scope
            }
        },
        logoutCurrentConnection: () => initialState
    }
})

export const { setCurrentConnection, logoutCurrentConnection } = connectionSlice.actions
export const connectionReducer = connectionSlice.reducer
export const selectCurrentConnection = (state: any) => state.currentConnection