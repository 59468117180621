import { configureStore, combineReducers } from '@reduxjs/toolkit'
import { authReducer } from './features/auth/authSlice'
import apiSlice from '@/lib/api/apiSlice'
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistReducer,
  persistStore,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { userReducer } from './features/users/userSlice'
import { connectionReducer } from './features/connections/connectionSlice'
import { usersReducer } from './features/users/usersSlice'
import { downloadsReducer } from './features/downloads/downloadSlice'

const rootReducer = combineReducers({
  [apiSlice.reducerPath]: apiSlice.reducer,
  auth: authReducer,
  currentUser: userReducer,
  currentUsers: usersReducer,
  currentConnection: connectionReducer,
  userDownloads: downloadsReducer
})

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['currentUser', 'currentUsers','currentConnection', 'userDownloads', apiSlice.reducerPath],
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const makeConfiguredStore = () =>
  configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }).concat(apiSlice.middleware),
  })

export const store = () => {
  const isServer = typeof window === 'undefined'
  if (isServer) {
    return makeConfiguredStore()
  } else {
    const persistedReducer = persistReducer(persistConfig, rootReducer)
    let store: any = configureStore({
      reducer: persistedReducer,
      middleware: (buildGetDefaultMiddleware) =>
        buildGetDefaultMiddleware({
          serializableCheck: {
            ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER, 'api/executeMutation/fulfilled'],
            ignoredPaths: ['api.mutations'],
          },
        }).concat(apiSlice.middleware),
    })
    store.__persistor = persistStore(store)
    return store
  }
}

export type AppStore = ReturnType<typeof store>
export type RootState = ReturnType<AppStore['getState']>
export type AppDispatch = AppStore['dispatch']
